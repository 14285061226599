body {
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

a {
  color: white;
}

@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px); /* Adjust the value to control the bounce height */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@font-face {
  font-family: 'Andale Mono';
  src: url('./fonts/Andale\ Mono\ V1.woff2') format('woff2'),
       url('./fonts/Andale\ Mono\ V1.woff') format('woff'),
       url('./fonts/AndaleMono.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Audiowide';
  src: url('./fonts/Audiowide-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'GT America';
  src: url('./fonts/GT\ America\ Extended\ Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.background {
  overflow: hidden;
  height: 100dvh;
  width: 100vw;
  background-color: #2A2826;
}

.fade-in {
  animation: fadeIn ease 2s; /* Adjust the timing as needed */
  -webkit-animation: fadeIn ease 2s; /* For Safari and Chrome */
  -moz-animation: fadeIn ease 2s; /* For Firefox */
  -o-animation: fadeIn ease 2s; /* For Opera */
  animation-fill-mode: forwards;
}

.appContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
  background: var(--turquoise-gradient, linear-gradient(180deg, #115F7F 0%, #052836 100%));
}

.centerContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  gap: 7vh;
  align-self: stretch;

  color: var(--white, #FFF);
  text-align: center;
  font-family: Rubik;
  font-style: normal;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15vh;
  align-self: stretch;
}

.title {
  font-size: min(6.4vw, 5vh);
  font-weight: 700;
  line-height: 115%; /* 27.6px */
  letter-spacing: -0.12px;
}

.tagline {
  font-size: min(10.67vw, 10vh);
  font-weight: 500;
  line-height: 115%; /* 46px */
  letter-spacing: -0.4px;
}

.actionWord {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-size: min(10.67vw, 10vh);
  font-weight: 500;
  line-height: 115%; /* 46px */
  letter-spacing: -0.4px;
  color: var(--turquoise, #35B7EB);
}

.logo {
  position: relative;
}

.milkLogo {
  display: flex;
  width: min(32vw,20vh);
  margin-top: 14vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  /*height: min(25.6vh,10vw);*/
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 0;
}

.spinningText {
  display: flex;
  width: min(16vw,10vh);
  margin-top: 17vh;
  margin-left: auto;
  margin-right: auto;
  /*left: min(32vw,20vh);*/
  left: min(30vw,18vh);
  right: 0;
  /*height: min(25.6vh,10vw);*/
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: rotate 8s linear infinite;
  position: absolute;
  z-index: 1;
}

.bottom {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 80px;
  gap: 8px;
  cursor: pointer;
}

.bottomText {
  color: var(--white, #FFF);
  text-align: center;
  /* label/label XL */
  font-family: Figtree;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  letter-spacing: -0.09px;
}

.arrow {
  display: flex;
  width: 14px;
  height: 23px;
  justify-content: center;
  align-items: center;
  animation: bounce 2s infinite; /* Adjust the duration as needed */
}

.signUpButton {
  display: flex;
  height: 4vh;
  width: min(230px, 50vw);
  padding: 6px 24px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  position: fixed;
  bottom: 130px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 1000px;
  border: 1px solid #FFF;
  cursor: pointer;
}

.signUpText {
  flex: 1 0 0;
  color: #FFF;
  text-align: center;
  /* label/label XL */
  font-family: Figtree;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  letter-spacing: -0.09px;
}
